exports.components = {
  "component---cache-caches-gatsby-plugin-offline-app-shell-js": () => import("./../../caches/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---cache-caches-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-activities-js": () => import("./../../../src/templates/activities.js" /* webpackChunkName: "component---src-templates-activities-js" */),
  "component---src-templates-all-tags-js": () => import("./../../../src/templates/allTags.js" /* webpackChunkName: "component---src-templates-all-tags-js" */),
  "component---src-templates-contacts-js": () => import("./../../../src/templates/contacts.js" /* webpackChunkName: "component---src-templates-contacts-js" */),
  "component---src-templates-home-js": () => import("./../../../src/templates/home.js" /* webpackChunkName: "component---src-templates-home-js" */),
  "component---src-templates-message-js": () => import("./../../../src/templates/message.js" /* webpackChunkName: "component---src-templates-message-js" */),
  "component---src-templates-restaurant-js": () => import("./../../../src/templates/restaurant.js" /* webpackChunkName: "component---src-templates-restaurant-js" */),
  "component---src-templates-rooms-js": () => import("./../../../src/templates/rooms.js" /* webpackChunkName: "component---src-templates-rooms-js" */),
  "component---src-templates-shop-js": () => import("./../../../src/templates/shop.js" /* webpackChunkName: "component---src-templates-shop-js" */),
  "component---src-templates-tags-js": () => import("./../../../src/templates/tags.js" /* webpackChunkName: "component---src-templates-tags-js" */)
}

